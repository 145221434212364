// extracted by mini-css-extract-plugin
export var allitems = "product-bar-module--allitems--HWC9D";
export var bisolduoSprey = "product-bar-module--bisolduoSprey--MClzn";
export var bisolduoSurup = "product-bar-module--bisolduoSurup--PDlb3";
export var bisolduocontainer = "product-bar-module--bisolduocontainer--iQfA4";
export var bisolnatur = "product-bar-module--bisolnatur--K7bmn";
export var bisolnaturcontainer = "product-bar-module--bisolnaturcontainer--H6IVA";
export var bisolviral = "product-bar-module--bisolviral--PMa5x";
export var bisolviralcontainer = "product-bar-module--bisolviralcontainer--9L57r";
export var categoryLine = "product-bar-module--categoryLine--TJsLq";
export var component = "product-bar-module--component--kW-lf";
export var container = "product-bar-module--container--BxVzn";
export var detailButton = "product-bar-module--detailButton--VbVDR";
export var image = "product-bar-module--image--dWsAp";
export var logo = "product-bar-module--logo--ow7T9";
export var multi = "product-bar-module--multi--ZB63a";
export var productitems = "product-bar-module--productitems--dnxiV";
export var ysu1 = "product-bar-module--ysu1--ycJKb";
export var ysu2 = "product-bar-module--ysu2--X5p-K";