import * as React from "react";
import * as productBarStyles from "./product-bar.module.scss";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { graphql, StaticQuery } from "gatsby";
import { ProductBarProps, ProductBarWrapper } from "./types";
import ProductBarItem from "./product-bar-item";
import Container from "../container/container";
import classNames from "classnames";

const ProductBarComp: React.FC<ProductBarProps> = ({
  items,
}: ProductBarProps) => {
  return (
    <Container fullWidth className="bg-orange lighten2">
      <hr className={classNames("line", "h5", "bg-orange")} />
      <Container>
        <div className={classNames("flexbox", productBarStyles.container, productBarStyles.allitems)}>
          {items?.map((node, i) => (
            <div key={i * 123} className={classNames(
              node.id === "3" ? productBarStyles.bisolviralcontainer : null,
              node.id === "2" ? productBarStyles.bisolduocontainer : null,
              node.id === "1" ? productBarStyles.bisolnaturcontainer : null,
            )}>
              <div key={node.id} style={{ textAlign: 'center', color: '#001489' }}>
                <h6>{node.category}</h6>
                <hr className={productBarStyles.categoryLine} />
                <div className={classNames(node.items.length > 1 ? productBarStyles.multi : null, productBarStyles.productitems)}>
                  {
                    node.items.map(item => (
                      <ProductBarItem key={item.id} item={item} />
                    ))
                  }
                </div>
              </div>
            </div>
          ))}
        </div>
      </Container>
    </Container >
  );
};

export const ProductBar: ProductBarWrapper = (props) => (
  <StaticQuery
    query={graphql`
      query ProductBarQuery {
        allProductBarJson {
          nodes {
            id
            category
            items {
              title
              category
              id
              properties
              className
              logo {
                publicURL
              }
              image {
                childImageSharp {
                  gatsbyImageData(
                    layout: FULL_WIDTH
                    breakpoints: [360, 768]
                    placeholder: NONE
                  )
                }
              }
              link {
                path
                text
              }
            }
          }
        }
      }
    `}
    render={(data) => (
      <ProductBarComp {...props} items={data.allProductBarJson.nodes} />
    )}
  />
);
