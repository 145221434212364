import * as React from "react";
import { PageProps } from "gatsby";
import * as styles from "./hakkimizda.scss";
import Layout from "../components/layout/layout";
import classNames from "classnames";
import { ProductBar } from "../components/product-bar/product-bar";
import { Helmet } from "react-helmet";
import { Breadcrumb } from "../components/breadcrumb/breadcrumb";
import Container from "../components/container/container";
import Heading from "../components/basic-components/heading/heading";

const PreventionsPage: React.FC<PageProps> = ({ location }: PageProps) => {
  return (
    <Layout
      hideTips={true}
      seoConfig={{
        title: "Hakkımızda",
        description: "Bisolvon, 50 den fazla ülkede tüketicilere soğuk algınlığı ve öksürük semptomlarında çözümler sunan global bir markadır. ",
      }}
    >
      <Helmet>
        <script type="application/ld+json">
          {`
            {
                "@context": "https://schema.org",
                "@type": "BreadcrumbList",
                "itemListElement": [
                  {"@type": "ListItem","position": 1,"name": "Ana Sayfa","item": "https://www.bisolnatur.com.tr/"},
                  {"@type": "ListItem","position": 2,"name": "Hakkımızda","item": "https://www.bisolnatur.com.tr/hakkimizda/"}
                ]
            }
          `}
        </script>
        <script type="application/ld+json">
          {`
          {
            "@context": "https://schema.org",
            "@type": "WebPage",
            "headline": "Bisolvon, 50 den fazla ülkede tüketicilere soğuk algınlığı ve öksürük semptomlarında çözümler sunan global bir markadır.",
            "name" : "Hakkımızda",
            "description": "Bisolvon, 50 den fazla ülkede tüketicilere soğuk algınlığı ve öksürük semptomlarında çözümler sunan global bir markadır.",
            "url": "https://www.bisolnatur.com.tr/hakkimizda",
          }
          `}
        </script>
      </Helmet>
      <Breadcrumb
        pathname={location.pathname}
        noBg={true}
        className={styles.breadcrumb}
      />
      <Container fullWidth>
        <div className="header">
          <Container>
            <Heading>Hakkımızda</Heading>
            <p className="paragraph">
              Bisolvon, 50 den fazla ülkede tüketicilere soğuk algınlığı ve öksürük semptomlarında çözümler sunan global bir markadır. Bisolvon çatısının altında Türkiye’de ilk lansman doğal içerikli öksürük şurubu olan Bisolnatur ile 2018 yılında yapılmıştır. 2021 yılında Bisolduo Şurup ve Bisolduo Action Sprey ile öksürüğün yanısıra boğaz ağrısını da rahatlatan çift etkili ürünlerimiz tüketicilere sunulmuştur.
              <br /><br />
              Amacımız, soğuk algınlığı ve öksürük kategorisinde yenilikçi ürünlerle geliştirmeye devam edeceğimiz portfoyümüzle insanların hayatlarını bilimin mucizeleriyle iyileştirmektir.
            </p>
          </Container>

        </div>

      </Container>
      <ProductBar />
    </Layout>
  );
};
export default PreventionsPage;
