import * as React from "react";
import * as productBarStyles from "./product-bar.module.scss";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { ProductBarItemProps } from "./types";
import classNames from "classnames";
import { GatsbyImage } from "gatsby-plugin-image";
import Button from "../basic-components/button/button";
import { ButtonTypesEnum } from "../basic-components/button/types";
import ysu from "../../images/YSU_2022_y_LG_3@3x.png";
import { Link } from "gatsby";

const ProductBarItem: React.FC<ProductBarItemProps> = ({
  item,
}: ProductBarItemProps) => {
  const {
    link: { path, text },
    title,
    className,
    properties,
    image: {
      childImageSharp: { gatsbyImageData },
    },
  } = item;

  return (
    <div
      style={{ alignItems: "center" }}
      className={classNames(
        "flexbox",
        productBarStyles.component,
        productBarStyles[className]
      )}
    >
      {item.id === "22" || item.id === "21" ? <img
        className={item.id === "22" ? productBarStyles.ysu1 : productBarStyles.ysu2}
        src={ysu}
        alt="Yılın seçilmiş ürünü"
        width="108"
        height="93"
      /> : null}
      <GatsbyImage
        className={productBarStyles.image}
        loading="lazy"
        image={gatsbyImageData}
        alt={title + " ürün görseli"}
      />
      {properties.map((property, i) => (
        <p key={i} style={{ order: 3 }}>
          {property}
        </p>
      ))}
      <div className={productBarStyles.detailButton}>
        <Link to={path} type={ButtonTypesEnum.orangeBorder}>{text}</Link>
      </div>
    </div>
  );
};

export default ProductBarItem;
